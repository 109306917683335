<template>
  <main class="main">
    <back-images-comp />
    <section v-if="itsData == ''" class="twentyseven no_info">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </section>
    <section v-else class="twenty">
      <div class="twenty__wrapper">
        <div class="twenty__block1">
          <h1 class="twenty__title">
            Информационно-технические<br />
            справочники
          </h1>
          <p class="twenty__subtitle">
            Перечень информационно-технических справочников по наилучшим
            доступным технологиям
          </p>
          <a
            href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=328&amp;etkstructure_id=1872"
            class="twenty__link-one"
            >Распоряжением Правительства РФ от 31 октября 2014 г. № 2178-р</a
          >
        </div>
        <div class="twenty__block2">
          <p class="twenty__subtitle2">
            в редакции распоряжений Правительства Российской Федерации
          </p>
          <a
            href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=462&amp;etkstructure_id=1872"
            class="twenty__link-two"
            >— от 29 августа 2015 г. № 1678-р*</a
          >
          <a
            href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=506&amp;etkstructure_id=1872"
            class="twenty__link-two"
            >— от 30 декабря 2015 г. № 2765-р**</a
          >
          <a
            href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=636&amp;etkstructure_id=1872"
            class="twenty__link-two"
            >— от 7 июля 2016 г. № 1444-р***</a
          >
          <a
            href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1757&etkstructure_id=1872"
            class="twenty__link-two"
            >— Поэтапный график актуализации ИТС НДТ</a
          >
          <p class="twenty__subtitle2">
            <router-link to="/documents/16"
              >Актуализированные ИТС НДТ 2021 года</router-link
            >
          </p>
          <p class="twenty__subtitle3">
            <router-link to="/itc-orders"
              >Приказы по технологическим показателям НДТ</router-link
            >
          </p>
        </div>
      </div>
    </section>

    <section class="twentyone">
      <div class="product">
        <div class="item head">
          <div class="one">
            <div class="num">№</div>
            <div class="title">Наименование ИТС НДТ</div>
          </div>
          <div class="two">
            <div class="type">Тип</div>
            <div class="status">Статус</div>
            <div class="docs">
              <div>2015-2017</div>
              <div>2019</div>
              <div>2020</div>
              <div>2021</div>
              <div>2022</div>
              <div>2023</div>
              <div>2024</div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">1</div>
            <div class="title">Целлюлозно-бумажное производство</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=484&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1858&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2101&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">2</div>
            <div class="title">Производство аммиака, минеральных удобрений и неорганических кислот</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1800&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=486&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1848&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">3</div>
            <div class="title">Производство меди</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализирован</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1801&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=488&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="one">
            <div class="num">4</div>
            <div class="title">Производство керамических изделий</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализирован</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=490&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2102&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">5</div>
            <div class="title">Производство стекла</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализирован</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=494&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1838&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="one">
            <div class="num">6</div>
            <div class="title">Производство цемента</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализирован</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=496&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1843&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">7</div>
            <div class="title">Производство извести</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=498&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1844&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">8</div>
            <div class="title">Очистка сточных вод при производстве продукции (товаров), выполнении работ и оказании услуг на крупных предприятиях </div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=500&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1850&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">9</div>
            <div class="title">Утилизация и обезвреживание отходов термическими способами</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1802&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1500&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><!--a href="https://burondt.ru/trg/2022/1935/info" target="_blank"><img src="img/45.png"></a--></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">10</div>
            <div class="title">Очистка сточных вод с использованием централизованных систем водоотведения поселений, городских округов</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализирован</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1803&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=504&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">11</div>
            <div class="title">Производство алюминия</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализирован</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1804&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=616&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1845&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2024/1955/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">12</div>
            <div class="title">Производство никеля и кобальта</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализирован</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1805&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=793&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">13</div>
            <div class="title">Производство свинца, цинка и кадмия</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1806&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1502&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">14</div>
            <div class="title">Производство драгоценных металлов</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1807&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1504&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">15</div>
            <div class="title">Утилизация и обезвреживание отходов (кроме термических способов)</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=799&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1667&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div><!--a href="https://burondt.ru/trg/2022/1934/info" target="_blank"><img src="img/45.png"></a--></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">16</div>
            <div class="title">Горнодобывающая промышленность. Общие процессы и методы</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=801&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2086&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">17</div>
            <div class="title">Размещение отходов производства и потребления ***</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=803&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1669&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2024/1963/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">18</div>
            <div class="title">Производство основных органических химических веществ</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализирован</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1808&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=805&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2089&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">19</div>
            <div class="title">Производство твердых и других неорганических химических веществ</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализирован</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1809&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1506&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2024/1951/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">20</div>
            <div class="title">Промышленные системы охлаждения</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=809&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">21</div>
            <div class="title">Производство оксида магния, гидроксида магния, хлорида магния ***</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=811&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1671&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">22</div>
            <div class="title">Очистка выбросов вредных (загрязняющих) веществ в атмосферный воздух при производстве продукции (товаров), а также при проведении работ и оказании услуг на крупных предприятиях</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=813&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">22.1</div>
            <div class="title">Общие принципы производственного экологического контроля и его метрологического обеспечения **</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=815&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1647&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">23</div>
            <div class="title">Добыча и обогащение руд цветных металлов</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1102&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">24</div>
            <div class="title">Производство редких и редкоземельных металлов **</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1810&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1508&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">25</div>
            <div class="title">Добыча и обогащение железных руд</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1106&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1675&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2078&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">26</div>
            <div class="title">Производство чугуна, стали и ферросплавов</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1108&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1677&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1846&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">27</div>
            <div class="title">Производство изделий дальнейшего передела черных металлов</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1110&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1679&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2087&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">28</div>
            <div class="title">Добыча нефти ***</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1112&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1640&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">29</div>
            <div class="title">Добыча природного газа</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1114&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2024/1953/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">30</div>
            <div class="title">Переработка нефти **</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1116&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1649&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">31</div>
            <div class="title">Производство продукции тонкого органического синтеза</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1118&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1683&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">32</div>
            <div class="title">Производство полимеров, в том числе биоразлагаемых</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1120&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1849&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">33</div>
            <div class="title">Производство специальных неорганических химикатов</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1811&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1510&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">34</div>
            <div class="title">Производство прочих основных неорганических химических веществ</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1812&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1512&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">35</div>
            <div class="title">Обработка поверхностей, предметов или продукции органическими растворителями</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1126&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2024/1961/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">36</div>
            <div class="title">Обработка поверхностей металлов и пластмасс с использованием электролитических или химических процессов</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1128&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2024/1960/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">37</div>
            <div class="title">Добыча и обогащение угля</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1130&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2097&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">38</div>
            <div class="title maxwidth">Сжигание топлива на крупных установках в целях производства энергии **</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализация</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1132&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1847&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2024/1957/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">39</div>
            <div class="title">Производство текстильных изделий (промывка, отбеливание, мерсеризация, крашение текстильных волокон, отбеливание, крашение текстильной продукции)</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Актуализация</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1134&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2098&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">40</div>
            <div class="title">Дубление, крашение, выделка шкур и кожи</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1136&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1673&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">41</div>
            <div class="title">Интенсивное разведение свиней</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1138&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2094&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">42</div>
            <div class="title">Интенсивное разведение сельскохозяйственной птицы</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1140&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2096&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">43</div>
            <div class="title">Убой продуктивных животных и производство мясной продукции и (или) продукции из мяса птицы</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1142&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2099&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">44</div>
            <div class="title">Производство продуктов питания</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1144&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2023/1940/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">45</div>
            <div class="title">Производство напитков, молока и молочной продукции</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1146&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2023/1941/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">46</div>
            <div class="title">Сокращение выбросов загрязняющих веществ, сбросов загрязняющих веществ при хранении и складировании товаров (грузов)</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div></div>
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1148&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">47</div>
            <div class="title">Системы обработки (обращения) со сточными водами и отходящими газами в химической промышленности</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1150&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2100&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">48</div>
            <div class="title">Повышение энергетической эффективности при осуществлении хозяйственной и (или) иной деятельности</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1153&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2095&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">49</div>
            <div class="title">Добыча драгоценных металлов **</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1155&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="http://burondt.ru/trg/2023/1949/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">50</div>
            <div class="title">Переработка природного и попутного газа **</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1157&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2024/1952/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">51</div>
            <div class="title">Литейное производство изделий из черных металлов</div>
          </div>
          <div class="two">
            <div class="type"></div>
            <div class="status"></div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/trg/2024/1964/info" target="_blank"><img src="img/45.png"></a></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">52</div>
            <div class="title">Обращение с отходами I и II классов опасности</div>
          </div>
          <div class="two">
            <div class="type">Отраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1785&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="one">
            <div class="num">53</div>
            <div class="title">Ликвидация объектов накопленного экологического вреда</div>
          </div>
          <div class="two">
            <div class="type">Межотраслевой</div>
            <div class="status">Утвержден</div>
            <div class="block">
              <div class="years">
                <div>2015-2017</div>
                <div>2019</div>
                <div>2020</div>
                <div>2021</div>
                <div>2022</div>
                <div>2023</div>
                <div>2024</div>
              </div>
              <div class="docs">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1842&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import getIts from "@/composables/its/its.js";
import BackImagesComp from "../BackImagesComp.vue";
import { onMounted } from "vue";

export default {
  name: "Itc",
  components: { BackImagesComp },
  setup() {
    const { fetchIts, itsData, loader } = getIts();
    onMounted(() => {
      fetchIts();
    });
    return { fetchIts, itsData, loader };
  },
  methods: {
    getAbbr(str) {
      var res = str.split("</strong>")[0].split("<strong>")[1];
      return res;
    },
    getFullName(str) {
      var res = str.split('"')[1];
      return res;
    },
  },
};
</script>

<style scoped>
/*---------------------------------------------------------------------------*/
  .item.head {
		font-weight: bold;
	}

	.one {
		min-width: 220px;
	}

  @media(max-width:1590px) and (min-width:1400px) {
    .one .title.maxwidth {
      max-width: 390px;
    }
  }

	.years div {
		text-align: center;
	}

	.docs div, .years div {
		min-width: 80px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    /*align-items: center;*/
	}

	.type, .status {
		min-width: 110px;
	}

	.one .title {
		font-weight: bold;
    max-width: 700px;
	}

	.product {
		box-shadow: 0 0 5px rgba(0,0,0,.5);
		width: 95%;
		margin: 0 auto 140px;
		padding: 20px;
	}

	.item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 15px;
		padding: 30px 20px;
		box-shadow: 0 0 .4vw rgba(0,0,0,.5);
		margin: 10px;
	}

	.item .one {
		display: flex;
		align-items: center;
	}

	.item .one .num {
		min-width: 30px;
	}

	.item .one .title {
		margin: 0;
	}

	.item .two {
		display: flex;
		gap: 10px;
	}

	.item .two .years {
		display: none;
	}

	.item .two .docs {
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		/*width: 55vw;*/
		text-align: center;
	}

	.item .two .docs {
		text-align: center;
	}

	.item .block {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
  
  .twenty__wrapper {
    width: 95%;
    margin: 50px auto;
    height: auto;
  }

  @media (max-width: 1400px) {
    .item {
      flex-direction: column;
      align-items: flex-start;
    }

    .item.head {
      display: none;
    }

    .item .two .years {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      width: 60vw;
    }

    .item .two {
      flex-direction: column;
    }
  }

  @media (max-width: 580px) {
    .product {
      width: auto;
    }

    .item .two .years {
      width: auto;
      display: flex;
      flex-direction: column;
    }

    .years div {
      min-width: auto;
    }

    .item .two .docs {
      display: flex;
      flex-direction: column;
    }

    .docs div {
      min-width: auto;
    }

    .item .block {
      display: flex;
      flex-direction: row;
      gap:  15px;
    }

    .item .two .years, .item .two .docs {
      gap: 10px;
    }
  }

</style>