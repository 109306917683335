<template>
    <div class="main">
        <BackImageComp />
        <section class="twentyseven">
            <div class="twentyseven__wrapper">
                <h1 class="twentyseven__title-main">Приказы по технологическим показателям наилучших доступных
                    технологий</h1>
                <div class="twentyseven__items">
                    <div class="twentyseven__item">
                        <span class="twentyseven__text">Перечень приказов Минприроды России об утверждении
                            технологических показателей наилучших доступных технологий</span>
                    </div>
                    <p class="twenty__subtitle3">
                        <router-link to="/itc">Перечень ИТС НДТ</router-link>
                    </p>
                </div>
            </div>
        </section>

        <section class="twentyone">
            <div class="twentyone__wrapper table">
                <div class="item bold">
                    <div class="one">
                        <div class="num">№</div>
                        <div>Наименование ИТС НДТ</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказы по технологическим показателям</div>
                        <div class="down">Скачать</div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">1.</div>
                        <div>«Производство целлюлозы, древесной массы, бумаги, картона»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 02.08.2023 г. № 479</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1316&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">2.</div>
                        <div>«Производство аммиака, минеральных удобрений и неорганических кислот»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 01.02.2021 г. № 67</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1306&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">3.</div>
                        <div>«Производство меди»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 01.02.2021 № 68</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1279&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">4.</div>
                        <div>«Производство керамических изделий»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 02.04.2019 г. № 209</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1269&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">5.</div>
                        <div>«Производство стекла»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 12.07.2023 г. № 427</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1250&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>
                
                <div class="item">
                    <div class="one">
                        <div class="num">6.</div>
                        <div>«Производство цемента»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 13.12.2023 № 826 (вступает в силу с 01.09.2024 г.)</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1270&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">7.</div>
                        <div>«Производство извести»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 29.06.2023 № 404</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1268&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">8.</div>
                        <div>«Очистка сточных вод при производстве продукции (товаров), выполнении работ и оказании услуг на крупных предприятиях»</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">9.</div>
                        <div>Утилизация и обезвреживание отходов термическими способами</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 12.11.2021 № 844</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1283&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">10.</div>
                        <div>«Очистка сточных вод с использованием централизованных систем водоотведения поселений, городских округов»</div>
                    </div>
                    <div class="two">
                        <div class="order">Постановление Правительства Российской Федерации от 15.09.2020 № 1430</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1931&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">11.</div>
                        <div>«Производство алюминия»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 31.08.2023 г. № 565</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1305&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">12.</div>
                        <div>«Производство никеля и кобальта»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 29.12.2020 г. № 1114</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1280&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">13.</div>
                        <div>«Производство свинца, цинка и кадмия»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 16.11.2021 № 854</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1281&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">14.</div>
                        <div>«Производство драгоценных металлов» **</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 16.11.2021 № 855</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1243&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">15.</div>
                        <div>«Утилизация и обезвреживание отходов (кроме обезвреживания термическим способом (сжигание отходов)» ***</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2224&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">16.</div>
                        <div>«Горнодобывающая промышленность. Общие процессы и методы» ***</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">17.</div>
                        <div>«Размещение отходов производства и потребления» ***</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2223&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">18.</div>
                        <div>«Производство основных органических химических веществ»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 29.12.2020 г. № 1116</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1325&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">19.</div>
                        <div>«Производство твердых и других неорганических химических веществ»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 16.11.2021 № 857</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1248&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">20.</div>
                        <div>«Промышленные системы охлаждения»</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">21.</div>
                        <div>	«Производство оксида магния, гидроксида магния, хлорида магния» ***</div>
                    </div>
                    <div class="two">
                        <div class="order">	Приказ Минприроды России от 08.11.2022 № 759</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1303&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">22.</div>
                        <div>«Очистка выбросов вредных (загрязняющих) веществ в атмосферный воздух при производстве продукции (товаров), а также при проведении работ и оказании услуг на крупных предприятиях»</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">22.1</div>
                        <div>«Общие принципы производственного экологического контроля и его метрологического обеспечения» **</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">23.</div>
                        <div>«Добыча и обогащение руд цветных металлов»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от. 02.04.2019 № 206</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1271&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">24.</div>
                        <div>«Производство редких и редкоземельных металлов» **</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 16.11.2021 № 856</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1247&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">25.</div>
                        <div>«Добыча и обогащение железных руд»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 15.11.2022 № 778</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1246&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">26.</div>
                        <div>«Производство чугуна, стали и ферросплавов»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 26.01.2023 г. № 46</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1300&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">27.</div>
                        <div>«Производство изделий дальнейшего передела черных металлов»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 19.07.2023 г. № 440</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1245&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">28.</div>
                        <div>«Добыча нефти» ***</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 27.05.2022 № 377</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1299&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">29.</div>
                        <div>«Добыча природного газа»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 17.07.2019 № 471</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1331&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">30.</div>
                        <div>«Переработка нефти» **</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 27.05.2022 № 376</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1272&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">31.</div>
                        <div>«Производство продукции тонкого органического синтеза»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 23.08.2023 г. № 538</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1278&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">32.</div>
                        <div>«Производство полимеров, в том числе биоразлагаемых»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 24.08.2023 № 541</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1282&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">33.</div>
                        <div>«Производство специальных неорганических химикатов»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 16.11.2021 № 853</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1275&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">34.</div>
                        <div>«Производство прочих основных неорганических химических веществ»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 12.11.2021 № 845</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1276&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">35.</div>
                        <div>«Обработка поверхностей, предметов или продукции органическими растворителями»	</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">36.</div>
                        <div>«Обработка поверхностей металлов и пластмасс с использованием электролитических или химических процессов»</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">37.</div>
                        <div>«Добыча и обогащение угля»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 25.03.2019 г. № 190</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1249&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">38.</div>
                        <div>«Сжигание топлива на крупных установках в целях производства энергии» **</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 25.04.2023 г. № 248</div>
                        <div class="down"><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1960&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">39.</div>
                        <div>«Производство текстильных изделий (промывка, отбеливание, мерсеризация, крашение текстильных волокон, отбеливание, крашение текстильной продукции)»</div>
                    </div>
                    <div class="two">
                        <div class="order">	Приказ Минприроды России от 02.04.2019 № 211</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1273&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">40.</div>
                        <div>«Дубление, крашение, выделка шкур и кожи»</div>
                    </div>
                    <div class="two">
                        <div class="order">	Приказ Минприроды России от 16.11.2022 № 780</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1277&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>
                
                <div class="item">
                    <div class="one">
                        <div class="num">41.</div>
                        <div>«Интенсивное разведение свиней»</div>
                    </div>
                    <div class="two">
                        <div class="order">Разрабатывается проект приказа</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1285&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">42.</div>
                        <div>«Интенсивное разведение сельскохозяйственной птицы»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 12.04.2019 № 232</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1284&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">43.</div>
                        <div>«Убой животных на мясокомбинатах, мясохладобойнях, побочные продукты животноводства»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 11.07.2019 г. № 457</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1312&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">44.</div>
                        <div>«Производство продуктов питания»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 06.06.2019 г. № 355</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1308&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">45.</div>
                        <div>«Производство напитков, молока и молочной продукции»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 12.04.2019 № 236</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1274&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">46.</div>
                        <div>«Сокращение выбросов загрязняющих веществ, сбросов загрязняющих веществ при хранении и складировании товаров (грузов)»</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">47.</div>
                        <div>«Системы обработки (обращения) со сточными водами и отходящими газами в химической промышленности»</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">48.</div>
                        <div>«Повышение энергетической эффективности при осуществлении хозяйственной и (или) иной деятельности»</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">49.</div>
                        <div>«Добыча драгоценных металлов» *</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 15.03.2019 г. № 163</div>
                        <div class="down"><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2002&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">50.</div>
                        <div>«Переработка природного и попутного газа» **</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 21.05.2019 № 319</div>
                        <div class="down"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1286&etkstructure_id=1872" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">51.</div>
                        <div>«Литейное производство изделий из черных металлов»</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">52.</div>
                        <div>«Обращение с отходами I и II классов опасности»</div>
                    </div>
                    <div class="two">
                        <div class="order">Приказ Минприроды России от 03.11.2023 № 734</div>
                        <div class="down"><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=2216&etkstructure_id=1872 " target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>

                <div class="item">
                    <div class="one">
                        <div class="num">53.</div>
                        <div>«Ликвидация объектов накопленного экологического вреда»</div>
                    </div>
                    <div class="two">
                        <div class="order"></div>
                        <div class="down"><a href="" target="_blank"><img src="img/45.png"></a></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BackImageComp from '../BackImagesComp.vue'
export default {
    name: "Contacts",
    components: { BackImageComp },
    setup() {
    },
};
</script>

<style scoped>
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        box-shadow: 0 0 .4vw rgba(0,0,0,.5);
    }
    .item.bold {
        font-weight: bold;
    }
    .item .one {
        display: flex;
        align-items: center;
        width: 30vw;
    }
    .item .one .num {
        min-width: 30px;
    }
    .item .two {
        display: flex;
        width: 40vw;
        align-items: center;
    }
    .item .two .order {
        width: 30vw;
    }
    .item .two .down {
        width: 10vw;
        text-align: center;
    }
    .twentyseven {
        padding: 0;
    }
    .twentyseven__wrapper {
        width: 95%;
        margin: 7px auto;
        margin-top: 4vw;
    }
    .twentyone__wrapper {
        width: 95%;
        margin: 7px auto;
        height: auto;
        box-shadow: 0 0 5px rgb(0 0 0 / 50%);
        padding: 20px 15px;
        margin-bottom: 200px;
        background: white;
        gap: 20px;
    }
    .twentyone__wrapper.table {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    @media(max-width:580px) {
        .item {
            flex-direction: column;
            gap: 30px;
        }

        .item .one {
            width: 100%;
        }

        .item .two {
            width: 50%;
            flex-direction: column;
            gap: 20px;
        }

        .item.bold {
            display: none;
        }
    }
</style>